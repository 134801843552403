<template>
  <div class="">
    <div  style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Progressbar</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
        </div>
        </div>
        <div class="mt-2"  >
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Show Progressbar:</span>
        <b-form-checkbox
        :id="`show-progressbar-${Date.now()}`"
            v-model="progressBar.showProgressbar"
                  ></b-form-checkbox>
      </label>
     
    </div>
        <div class="" :class="[progressBar.showProgressbar?'':'progressbar-disabled']">
          <hr>
        <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>RTL direction:</span>
        <b-form-checkbox
        :id="`show-directionRTL-${Date.now()}`"
            v-model="progressBar.RTLdirection"
                  ></b-form-checkbox>
      </label>
          
    <hr>
    <div class="">
      <Label class="editor-label">Lines:</Label>
      <div class="d-flex align-items-center my-1">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="linev1"
                @change="updateProgressBarTypeAndVariant('line', 'v1')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="298"
                height="12"
                viewBox="0 0 298 12"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="2" width="298" height="8" rx="4" fill="#BFBFBF" />
                <rect y="2" width="188" height="8" rx="4" fill="var(--secondary-color)" />
              </svg>
            </div>
          </div>
          <div class="d-flex align-items-center my-2">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="linev2"
                @change="updateProgressBarTypeAndVariant('line', 'v2')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="298"
                height="12"
                viewBox="0 0 298 12"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="2" width="298" height="8" rx="4" fill="#BFBFBF" />
                <rect y="2" width="110" height="8" rx="4" fill="var(--secondary-color)" />
                <circle cx="104" cy="6" r="6" fill="var(--secondary-color)" />
                <circle cx="104" cy="6" r="4" fill="white" />
                <circle cx="292" cy="6" r="6" fill="#BFBFBF" />
                <circle cx="292" cy="6" r="4" fill="white" />
                <circle cx="192" cy="6" r="6" fill="#BFBFBF" />
                <circle cx="192" cy="6" r="4" fill="white" />
                <circle cx="6" cy="6" r="6" fill="var(--secondary-color)" />
                <circle cx="6" cy="6" r="4" fill="white" />
              </svg>
            </div>
          </div>
          <!-- v2 End -->
          <!-- V3 Start -->
          <div class="d-flex align-items-center my-2">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="linev3"
                @change="updateProgressBarTypeAndVariant('line', 'v3')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="298"
                height="12"
                viewBox="0 0 298 12"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="2" width="298" height="8" rx="4" fill="#BFBFBF" />
                <rect y="2" width="110" height="8" rx="4" fill="var(--secondary-color)" />
                <circle cx="104" cy="6" r="6" fill="var(--secondary-color)" />
                <circle cx="292" cy="6" r="6" fill="#BFBFBF" />
                <path
                  d="M289.376 7.864V6.984L291.992 3.24H293.296V6.888H294V7.864H293.296V9H292.176V7.864H289.376ZM292.248 4.44L290.608 6.888H292.248V4.44Z"
                  fill="white"
                />
                <circle cx="192" cy="6" r="6" fill="#BFBFBF" />
                <path
                  d="M189.44 4.704C189.467 4.17067 189.653 3.76 190 3.472C190.352 3.17867 190.813 3.032 191.384 3.032C191.773 3.032 192.107 3.10133 192.384 3.24C192.661 3.37333 192.869 3.55733 193.008 3.792C193.152 4.02133 193.224 4.28267 193.224 4.576C193.224 4.912 193.136 5.19733 192.96 5.432C192.789 5.66133 192.584 5.816 192.344 5.896V5.928C192.653 6.024 192.893 6.19467 193.064 6.44C193.24 6.68533 193.328 7 193.328 7.384C193.328 7.704 193.253 7.98933 193.104 8.24C192.96 8.49067 192.744 8.688 192.456 8.832C192.173 8.97067 191.832 9.04 191.432 9.04C190.829 9.04 190.339 8.888 189.96 8.584C189.581 8.28 189.381 7.832 189.36 7.24H190.448C190.459 7.50133 190.547 7.712 190.712 7.872C190.883 8.02667 191.115 8.104 191.408 8.104C191.68 8.104 191.888 8.02933 192.032 7.88C192.181 7.72533 192.256 7.528 192.256 7.288C192.256 6.968 192.155 6.73867 191.952 6.6C191.749 6.46133 191.435 6.392 191.008 6.392H190.776V5.472H191.008C191.765 5.472 192.144 5.21867 192.144 4.712C192.144 4.48267 192.075 4.304 191.936 4.176C191.803 4.048 191.608 3.984 191.352 3.984C191.101 3.984 190.907 4.05333 190.768 4.192C190.635 4.32533 190.557 4.496 190.536 4.704H189.44Z"
                  fill="white"
                />
                <circle cx="6" cy="6" r="6" fill="var(--secondary-color)" />
                <g clip-path="url(#clip0_1481_6711)">
                  <path
                    d="M4.99993 7.39001L3.60993 6.00001L3.1366 6.47001L4.99993 8.33334L8.99993 4.33334L8.52993 3.86334L4.99993 7.39001Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1481_6711">
                    <rect
                      width="8"
                      height="8"
                      fill="white"
                      transform="translate(2 2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
    </div> 
    <hr>
    <label for="" class="editor-label">Dots</label>
    <div class="DotsRadioSection">
          <!-- V1 Start -->
          <div class="d-flex align-items-center my-1">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="dotsv1"
                @change="updateProgressBarTypeAndVariant('dots', 'v1')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="293"
                height="8"
                viewBox="0 0 293 8"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="15" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="30" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="45" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="60" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="75" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="90" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="105" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="120" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="135" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="150" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="165" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="180" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="195" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="210" width="8" height="8" rx="4" fill="var(--secondary-color)" />
                <rect x="225" width="8" height="8" rx="4" fill="#BFBFBF" />
                <rect x="240" width="8" height="8" rx="4" fill="#BFBFBF" />
                <rect x="255" width="8" height="8" rx="4" fill="#BFBFBF" />
                <rect x="270" width="8" height="8" rx="4" fill="#BFBFBF" />
                <rect x="285" width="8" height="8" rx="4" fill="#BFBFBF" />
              </svg>
            </div>
          </div>
          <!-- v1 End -->
          <!-- V2 Start -->
          <div class="d-flex align-items-center my-2">
            <div class="">
              <b-form-radio
                class="CustomRadio"
                v-model="currentProgressType"
                name="variant"
                value="dotsv2"
                @change="updateProgressBarTypeAndVariant('dots', 'v2')"
              ></b-form-radio>
            </div>

            <div class="versionImgContainer">
              <svg
                width="298"
                height="12"
                viewBox="0 0 298 12"
                style="width: 100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="104" cy="6" r="6" fill="var(--secondary-color)" />
                <g clip-path="url(#clip0_1481_6746)">
                  <path
                    d="M103 7.39001L101.61 6.00001L101.137 6.47001L103 8.33334L107 4.33334L106.53 3.86334L103 7.39001Z"
                    fill="white"
                  />
                </g>
                <circle cx="292" cy="6" r="6" fill="#BFBFBF" />
                <path
                  d="M289.376 7.864V6.984L291.992 3.24H293.296V6.888H294V7.864H293.296V9H292.176V7.864H289.376ZM292.248 4.44L290.608 6.888H292.248V4.44Z"
                  fill="white"
                />
                <circle cx="192" cy="6" r="6" fill="#BFBFBF" />
                <path
                  d="M189.44 4.704C189.467 4.17067 189.653 3.76 190 3.472C190.352 3.17867 190.813 3.032 191.384 3.032C191.773 3.032 192.107 3.10133 192.384 3.24C192.661 3.37333 192.869 3.55733 193.008 3.792C193.152 4.02133 193.224 4.28267 193.224 4.576C193.224 4.912 193.136 5.19733 192.96 5.432C192.789 5.66133 192.584 5.816 192.344 5.896V5.928C192.653 6.024 192.893 6.19467 193.064 6.44C193.24 6.68533 193.328 7 193.328 7.384C193.328 7.704 193.253 7.98933 193.104 8.24C192.96 8.49067 192.744 8.688 192.456 8.832C192.173 8.97067 191.832 9.04 191.432 9.04C190.829 9.04 190.339 8.888 189.96 8.584C189.581 8.28 189.381 7.832 189.36 7.24H190.448C190.459 7.50133 190.547 7.712 190.712 7.872C190.883 8.02667 191.115 8.104 191.408 8.104C191.68 8.104 191.888 8.02933 192.032 7.88C192.181 7.72533 192.256 7.528 192.256 7.288C192.256 6.968 192.155 6.73867 191.952 6.6C191.749 6.46133 191.435 6.392 191.008 6.392H190.776V5.472H191.008C191.765 5.472 192.144 5.21867 192.144 4.712C192.144 4.48267 192.075 4.304 191.936 4.176C191.803 4.048 191.608 3.984 191.352 3.984C191.101 3.984 190.907 4.05333 190.768 4.192C190.635 4.32533 190.557 4.496 190.536 4.704H189.44Z"
                  fill="white"
                />
                <circle cx="6" cy="6" r="6" fill="var(--secondary-color)" />
                <line
                  x1="12"
                  y1="6.75"
                  x2="98"
                  y2="6.75001"
                  stroke="#BFBFBF"
                  stroke-width="0.5"
                  stroke-dasharray="2 2"
                />
                <line
                  x1="110"
                  y1="6.75"
                  x2="186"
                  y2="6.74999"
                  stroke="#BFBFBF"
                  stroke-width="0.5"
                  stroke-dasharray="2 2"
                />
                <line
                  x1="198"
                  y1="6.75"
                  x2="286"
                  y2="6.74999"
                  stroke="#BFBFBF"
                  stroke-width="0.5"
                  stroke-dasharray="2 2"
                />
                <defs>
                  <clipPath id="clip0_1481_6746">
                    <rect
                      width="8"
                      height="8"
                      fill="white"
                      transform="translate(100 2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <!-- v2 End -->
        </div>
       
      <hr>

      <div class="d-flex gap align-items-center">
<div class="d-flex align-items-center w-100" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number" min="0" max="20"  v-model="progressBar.progressStyles.lineHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
<Stroke  v-if="progressBar.progressBarType == 'line'" class="w-100"  :isBorder="false"
:borderRadius.sync="progressBar.progressStyles.borderRadius"  ></Stroke>
      </div>
<hr>
<div class="">
  <label class="editorStyleTitle"  >Text:</label>
  <input  type="text" v-model="progressBar.questionText"  placeholder="Question Text" class="editor-input-control w-100">
</div>
<FontFamily class="mt-3"  v-model="progressBar.progressStyles.fontFamily"></FontFamily>
<TextAlign class="mt-3" v-model="progressBar.progressStyles.questionAlign"></TextAlign>
<SelectColor class="mt-3" :label="'Progressbar Color:'" :opacity.sync="progressBar.progressStyles.LineBgOpacity" v-model="progressBar.progressStyles.LineBgColor"></SelectColor>        
<SelectColor class="mt-3" :label="'Controls Color:'" :opacity.sync="progressBar.progressStyles.LineControlOpacity" v-model="progressBar.progressStyles.LineControlBg"></SelectColor>        
<SelectColor class="mt-3" :label="'Progressbar Color:'" :isOpacity="false" v-model="progressBar.progressStyles.textColor"></SelectColor>        
</div>
</div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
import { mapMutations, mapGetters } from "vuex";
import Stroke from "../../customize/MiniComponents/Stroke.vue";
import FontFamily from "../../customize/MiniComponents/FontFamily.vue";
import TextAlign from "../../customize/MiniComponents/TextAlign.vue";
import SelectColor from "../../customize/MiniComponents/SelectColor.vue";
export default {
  components:{
    Stroke,
    FontFamily,
    TextAlign,
    SelectColor
  },
  data() {
    return {
      progressBar: {},
      progressView: "progressType",
      currentProgressType: "",
    };
  },
  computed: {
    ...mapGetters(["getProgressBar"]),
    currentProgressBarValue() {
      return this.getProgressBar;
    },
    
getFontFamilyList() {
return fontFamilyList;
},
progressStyleFont(){
      return this.progressBar.progressStyles.fontFamily
    },
  },
  watch: {
    progressBar: {
      deep: true,
      handler(newValue ,oldVal) {
        this.setProgressBar(this.progressBar);
        if(Object.keys(oldVal).length != 0 && newValue == oldVal ){
          this.setIsAnyPageEdited(true)
        }
      
      },
    },
    progressStyleFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  },
  methods: {
    ...mapMutations(["setProgressBar","setIsAnyPageEdited"]),
    checkProgressHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorProgressOutput(value) {
      let isHex = this.checkProgressHexCode(value);
      if (isHex) {
        return value;
      } else {
        return "Gradient input";
      }
    },
    checkControlHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorControlOutput(value) {
      let isHex = this.checkControlHexCode(value);
      if (isHex) {
        return value;
      } else {
        return "Gradient input";
      }
    },
    updateProgressBarTypeAndVariant(type, variant) {
      if (type && variant) {
        this.progressBar.progressBarType = type;
        this.progressBar.progressBarVariant = variant;
      }
    },
  },
  mounted() {
    if (this.currentProgressBarValue) {
      this.progressBar = this.currentProgressBarValue;
      this.currentProgressType =
        this.progressBar.progressBarType + this.progressBar.progressBarVariant;

      if (
        !Object.prototype.hasOwnProperty.call(
          this.progressBar.progressStyles,
          "fontFamily"
        )
      ) {
        this.progressBar.progressStyles.fontFamily = "Poppins";
      }
    }

    this.progressView = "progressType";
  },
};
</script>

<style scoped>


.CustomRadio >>> .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--secondary-color) !important;
  border-radius: 50%;
}

.progressbar-disabled{
 pointer-events: none;
 opacity: 0.5;
}
</style>
